import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'

const SwiperCarousel = ({
  enableDesktop = true,
  enableMobile = true,
  showNavigationDesktop = false,
  showNavigationMobile = false,
  showNavigationTablet = false,
  slidesPerViewDesktop = 3,
  slidesPerViewMobile = 1.5,
  slidesPerViewTablet = 2.5,
  spaceBetweenDesktop = 30,
  spaceBetweenMobile = 8,
  slidesOffsetAfterDesktop = 0,
  slidesOffsetBeforeDesktop = 0,
  slidesOffsetAfterTablet = 0,
  slidesOffsetBeforeTablet = 0,
  slidesOffsetAfterMobile = 0,
  slidesOffsetBeforeMobile = 0
}) => ({
  init () {
    this.initSwiper()
  },

  swiper: null,

  initSwiper () {
    this.swiper = new Swiper(this.$refs.swiper, {
      breakpoints: {
        1024: {
          enabled: enableDesktop,
          navigation: {
            enabled: showNavigationDesktop,
            nextEl: this.$refs.navNext,
            prevEl: this.$refs.navPrev
          },
          spaceBetween: spaceBetweenDesktop,
          slidesOffsetAfter: slidesOffsetAfterDesktop,
          slidesOffsetBefore: slidesOffsetBeforeDesktop,
          slidesPerView: slidesPerViewDesktop
        },
        750: {
          enabled: enableDesktop,
          navigation: {
            enabled: showNavigationTablet,
            nextEl: this.$refs.navNext,
            prevEl: this.$refs.navPrev
          },
          slidesOffsetAfter: slidesOffsetAfterTablet,
          slidesOffsetBefore: slidesOffsetBeforeTablet,
          slidesPerView: slidesPerViewTablet
        }
      },
      enabled: enableMobile,
      navigation: {
        enabled: showNavigationMobile,
        nextEl: this.$refs.navNext,
        prevEl: this.$refs.navPrev
      },
      modules: [Navigation],
      slidesOffsetAfter: slidesOffsetAfterMobile,
      slidesOffsetBefore: slidesOffsetBeforeMobile,
      spaceBetween: spaceBetweenMobile,
      slidesPerView: slidesPerViewMobile,
      resistanceRatio: 0
    })
  }
})

export default SwiperCarousel
