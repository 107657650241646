import 'vite/modulepreload-polyfill'
import Alpine from 'alpinejs'

import SwiperCarousel from '@scripts/components/swiper-carousel'

window.Alpine = Alpine

document.addEventListener('alpine:init', () => {
  // store

  // components

  // sections
  Alpine.data('swiperCarousel', SwiperCarousel)
})

Alpine.start()
